
























































import mixins from 'vue-typed-mixins'
import {
  placeStore,
  snackbarStore,
} from '@/store'
import PlaceCreateEditFields from '@/components/Place/CreateEditFields.vue'
import {
  newPlace,
} from '@/util/place'
import {
  form,
} from '@/mixins'

export default mixins(form)
  .extend({
    components: {
      PlaceCreateEditFields,
    },
    data: () => ({
      loadingCreate: false,
      newPlace: newPlace(),
    }),
    methods: {
      async create () {
        if (!this.validateForm()) return
        try {
          this.loadingCreate = true
          await placeStore.create(this.newPlace)
          this.$router.go(-1)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Place successfully created`,
          })
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error updating place`,
          })
        }
        this.loadingCreate = false
      },
      async reset () {
        this.newPlace = newPlace()
      },
    },
  })
